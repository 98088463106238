import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './assets/css/base/__custom__.scss';
import './assets/css/base/root.css';
import './assets/css/base/color.css';
import './assets/css/base/font.css';
import './assets/css/base/overlay.css';
import './assets/css/base/width.css';
import './assets/css/base/height.css';
import './assets/css/base/svg.css';
import './assets/css/base/border.css';
import './assets/css/base/transform.css';
import './assets/css/base/responsive.css';

import './assets/css/_root_.css';

import CONF from './config';

import Simulador from './pages/Simulador';

import PackageProvider from './context/PackageProvider';

import Wrapper from './components/Wrapper';

export default function App() {
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    } else {
      document.documentElement.className = 'normal';
      localStorage.setItem('theme', 'normal');
    }
  }, []);

  return (
    <PackageProvider>
      <Routes>
        <Route
          path='/thanks'
          element={
            <Wrapper
              text='Recebemos suas informações e entraremos em contato em breve'
              clsnmText='fw500 fs16'
              textHeader='Obrigado!'
              conf={CONF}
            />
          }
        />
        <Route
          exact
          path='/'
          element={<Simulador />}
        />

        <Route
          path='/:corretor'
          element={<Simulador />}
        />

        <Route
          exact
          path='/404'
          name='Page 404'
          element={
            <Wrapper
              text='Ooops! Página não encontrada'
              clsnmText='fw-500 fs-16'
              textHeader='Erro 404'
              conf={CONF}
            />
          }
        />
        <Route
          path='*'
          element={
            <Wrapper
              text='Ooops! Página não encontrada'
              clsnmText='fw-500 fs-16'
              textHeader='Erro 404'
              conf={CONF}
            />
          }
        />
      </Routes>
    </PackageProvider>
  );
}
