import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import Swal from 'sweetalert2';
import { Button, Form } from 'react-bootstrap';
import * as SIMULACAO_JS from '../assets/js/simulacao.js';

import { images } from '../config';
import * as api from '../services/requests';
import * as end from '../services/endpoints';

import LoadingDots from '../components/LoadingDots';
import NavTheme from '../components/NavTheme';
import FormGroup from '../components/FormGroup';
import PackageContext from '../context/PackageContext';

import '../assets/css/app/simulacao.css';

export default function Simulador({ full = true }) {
  const { theme, SEO } = useContext(PackageContext);
  const { corretor } = useParams();

  const {
    data_int,
    is_valid_phone,
    is_compound_name,
    is_valid_cpf,
    is_valid_email,
    is_valid_nascimento,
    mascaraMoeda,
    numberReFormat,
    not_blank,
  } = SIMULACAO_JS;

  const { cidades, operacoes, sim, simBWAPI } = end;

  const { get, post } = api;

  const imovel_descricao = {
    1: 'Aquisição de Imóvel Novo',
    2: 'Construção',
    3: 'Reforma ou Ampliação',
    4: 'Aquisição de Imóvel Usado',
    6: 'Aquisição de Terreno',
    7: 'Empréstimo Garantido por Imóvel',
    11: 'Imóveis CAIXA',
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [operations, setOperations] = useState([]);
  const [simulacao, setSimulacao] = useState({
    whatsapp: '',
    email: '',
    nome: '',
    nascimento: '',
    cpf: '',
    simulacao_completa: full ? 1 : 0,
    imovel_valor: '',
    imovel_cidade: 'TAUBATE',
    imovel_codigo: '',
    imovel_descricao: '',
    compor: 0,
    estado_civil: '',
    relacionamento: '',
    renda: '',
    fgts: '',
    recursos: '',
    comprovar: [],
    outro_nome: '',
    outro_nascimento: '',
    outro_cpf: '',
    outro_renda: '',
    outro_fgts: '',
    outro_recursos: '',
    outro_comprovar: [],
    beneficiado: '',
    servidor_publico: '',
    anos_fgts: '',
    dependentes: '',
    clt: '',
    possui_imovel: '',
    possui_imovel_cidade: '',
    informacoes: '',
    compartilhar_corretor: 1,
    corretor: '',
    imobiliaria: '',
    termos: 0,
    imobiliaria_codigo: '',
    corretor_codigo: '',
  });

  const [simulacaoHid, setSimulacaoHid] = useState({
    imovel_valor: 0,
    renda: 0,
    fgts: 0,
    recursos: 0,
    outro_renda: 0,
    outro_fgts: 0,
    outro_recursos: 0,
  });

  const get_info = async () => {
    try {
      const [citiesResponse, operationsResponse] = await Promise.all([
        get(cidades.read, {}),
        get(operacoes.read, {}),
      ]);

      const { data: citiesData } = citiesResponse;
      const { data: operationsData } = operationsResponse;

      if (corretor) {
        const corretorData = await post(end.rst.read, {
          alias: corretor,
        });
        if (corretorData.data) {
          const rst = corretorData.data[0];
          setSimulacao({
            ...simulacao,
            corretor: rst.CORRETOR_NOME,
            corretor_codigo: rst.CORRETOR_CODIGO,
            imobiliaria: rst.IMOBILIARIA_NOME,
            imobiliaria_codigo: rst.IMOBILIARIA_CODIGO,
          });
        }
      }

      setCities(citiesData.items);
      setOperations(operationsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_info();
    // eslint-disable-next-line
  }, []);

  const updateSimulacaoState = (key, value) => {
    setSimulacao({ ...simulacao, [key]: value });
    if (key in simulacaoHid) {
      setSimulacao({
        ...simulacao,
        [key]: mascaraMoeda(value),
      });
      setSimulacaoHid({
        ...simulacaoHid,
        [key]: numberReFormat(value),
      });
    }
  };

  const updateState = (event, isNumber = false) => {
    const element = document.getElementById(event.target.id);
    if (isNumber) {
      setSimulacao({
        ...simulacao,
        [event.target.id]: mascaraMoeda(element.value),
      });
      setSimulacaoHid({
        ...simulacaoHid,
        [event.target.id]: numberReFormat(element.value),
      });
    } else {
      setSimulacao({ ...simulacao, [event.target.id]: element.value });
    }
  };

  const checkButtonsFields = () => {
    let errorMessage = '';
    let requiredFields = ['imovel_descricao', 'estado_civil'];
    if (full && simulacao.compor) {
      requiredFields.push('relacionamento');
    }
    const fieldReference = {
      imovel_descricao: 'Tipo de Financiamento',
      estado_civil: 'Seu Estado Civil',
      relacionamento: 'Relacionamento com a pessoa que irá compor renda',
      comprovar: 'Sua comprovação de renda',
      outro_comprovar: 'Comprovação de renda da pessoa que irá compor renda',
    };

    requiredFields.forEach((field) => {
      if (!not_blank(simulacao[field])) {
        document.getElementById(`div_${field}`).classList.add('invalid-box');
        errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
      } else {
        document.getElementById(`div_${field}`).classList.remove('invalid-box');
      }
    });

    const comprovacaoFields =
      full && simulacao.compor
        ? ['comprovar', 'outro_comprovar']
        : ['comprovar'];

    comprovacaoFields.forEach((field) => {
      if (simulacao[field].length > 0) {
        document.getElementById(`div_${field}`).classList.remove('invalid-box');
      } else {
        document.getElementById(`div_${field}`).classList.add('invalid-box');
        errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
      }
    });

    if (errorMessage.length > 0) {
      errorMessage = `<div id="swalDivErrorSimulacao"><p id="swalDivErrorSimulacao-title">Preencha os campos obrigatórios:</p><div  id="swalDivErrorSimulacao-items">${errorMessage}</div></div>`;
    }

    return errorMessage;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const checked = checkButtonsFields();

    if (checked.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        background: theme === 'light' ? '#fff' : '#333',
        html: checked,
        iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
        customClass: {
          title: 'fs24 fw600 textContrastColor',
          htmlContainer: 'fs12',
        },
      });
      return;
    }

    setLoading(true);

    setSimulacao({
      ...simulacao,
      anos_fgts: parseInt(simulacao.anos_fgts),
      dependentes: parseInt(simulacao.dependentes),
      clt: parseInt(simulacao.clt),
      possui_imovel: parseInt(simulacao.possui_imovel),
      beneficiado: parseInt(simulacao.beneficiado),
      servidor_publico: parseInt(simulacao.servidor_publico),
      imovel_descricao: imovel_descricao[simulacao.imovel_codigo],
    });

    try {
      const [simulacaoCreate] = await Promise.all([
        await post(sim.create, {
          ...simulacao,
          nascimento: data_int(simulacao.nascimento),
          outro_nascimento:
            full && simulacao.compor
              ? data_int(simulacao.outro_nascimento)
              : null,
          imovel_descricao: imovel_descricao[simulacao.imovel_codigo],
          comprovar: simulacao.comprovar.toString(),
          outro_comprovar: simulacao.outro_comprovar.toString() || null,
          imovel_valor: simulacaoHid.imovel_valor,
          renda: simulacaoHid.renda,
          fgts: simulacaoHid.fgts,
          recursos: simulacaoHid.recursos,
          outro_renda: simulacaoHid.outro_renda,
          outro_fgts: simulacaoHid.outro_fgts,
          outro_recursos: simulacaoHid.outro_recursos,
        }),
      ]);

      const { data: response } = simulacaoCreate;

      const formData = new FormData();
      Object.keys(simulacao).forEach((key) => {
        formData.append(key, simulacao[key]);
      });

      formData.set('imovel_valor', simulacaoHid.imovel_valor);
      formData.set('renda', simulacaoHid.renda);
      formData.set('fgts', simulacaoHid.fgts);
      formData.set('recursos', simulacaoHid.recursos);
      formData.set('outro_renda', simulacaoHid.outro_renda);
      formData.set('outro_fgts', simulacaoHid.outro_fgts);
      formData.set('outro_recursos', simulacaoHid.outro_recursos);

      formData.append(
        'logo_link',
        'https://www.hamanncorp.com/logotext512.png',
      );
      formData.append('brand', 'hamanncorp');

      await api.postAPI(simBWAPI.mail, formData);

      const { error, error_message } = response;

      if (!error) {
        navigate('/thanks');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error_message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // }

    setLoading(false);
  };

  const reqField = () => {
    const color = theme === 'light' ? 'cRed' : 'cDarkRed';
    return <span className={`fs10 ${color}`}>*</span>;
  };

  const validate = (e, isValid, isNumber = false) => {
    const feedbackMessages = {
      nome: 'Informe seu nome completo',
      email: 'Informe seu e-mail corretamente',
      whatsapp: 'Informe um número de WhatsApp válido',
      cpf: 'Informe um CPF válido',
      nascimento: 'Informe a data de nascimento corretamente',
      imovel_valor: 'Informe o valor do imóvel',
      imovel_cidade: 'Informe a cidade do imóvel',
      imovel_descricao: 'Informe o tipo de financiamento',
      renda: 'Informe a renda',
      fgts: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      recursos: 'Informe o saldo de outros recursos próprios',
      outro_nome: 'Informe o nome completo',
      outro_cpf: 'Informe um CPF válido',
      outro_nascimento: 'Informe a data de nascimento corretamente',
      outro_renda: 'Informe a renda',
      outro_fgts: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      outro_recursos: 'Informe o saldo de outros recursos próprios',
    };

    const inputElement = document.getElementById(e.target.id);
    const feedbackElement = document.getElementById(`fdbk_${e.target.id}`);
    const boxElement = document.getElementById(`box_${e.target.id}`);

    if (isValid) {
      inputElement.classList.remove('is-invalid');
      feedbackElement.innerText = '';
      boxElement.classList.remove('invalid-box');
    } else {
      if (e.type === 'blur' || e.type === 'accept') {
        inputElement.classList.add('is-invalid');
        feedbackElement.innerText = feedbackMessages[e.target.id];
        boxElement.classList.add('invalid-box');
      }
    }
    updateState(e, isNumber);
  };

  const colorSet =
    theme === 'light' ? 'secondary hovbgLightBlueTransp hovWhite' : 'secondary';
  const buttonColor = `outline-${colorSet} textContrast`;
  const buttonColorActive =
    theme === 'light' ? 'outline-primary active' : 'outline-warning active';

  const colorChecked = theme === 'light' ? 'cBlue fw600' : 'cYellow fw600';

  const estadoCivilButtons = () => {
    const estadoCivil = [
      'Solteiro(a)',
      'Casado(a)',
      'Divorciado(a)',
      'Viúvo(a)',
      'União Estável',
    ];

    return estadoCivil.map((item) => (
      <Button
        key={item}
        size='sm'
        variant={
          simulacao.estado_civil === item ? buttonColorActive : buttonColor
        }
        className={`m-2 wpMin100 ${
          simulacao.estado_civil === item ? 'active' : ''
        }`}
        onClick={() => {
          const compor = item === 'Casado(a)' ? 1 : 0;
          console.log(item);
          document
            .getElementById('div_estado_civil')
            .classList.remove('invalid-box');
          const relacionamento = item === 'Casado(a)' ? 'Cônjuge' : '';
          setSimulacao({
            ...simulacao,
            estado_civil: item,
            compor,
            relacionamento,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  useEffect(() => {
    if (full && simulacao.compor && simulacao.estado_civil === 'Casado(a)') {
      document.getElementById('div_relacionamento').classList.add('divHide');
    }
    // eslint-disable-next-line
  }, [simulacao.compor, simulacao.estado_civil]);

  const comprovarRendaButtons = (outro = false) => {
    const comprovarRenda = [
      'Holerite',
      'Extrato bancário',
      'Declaração de Imposto de Renda',
      'Pró Labore',
      'Não tenho como comprovar',
    ];

    const param = outro ? 'outro_comprovar' : 'comprovar';

    return comprovarRenda.map((item) => (
      <Button
        key={item}
        variant={
          simulacao[param].includes(item) ? buttonColorActive : buttonColor
        }
        size='sm'
        className={`m-1 ${simulacao[param].includes(item) ? 'active' : ''}`}
        onClick={() => {
          let comprovar = simulacao[param];
          if (comprovar.includes(item)) {
            comprovar = comprovar.filter((i) => i !== item);
          } else {
            comprovar.push(item);
          }
          if (comprovar.length === 0) {
            document
              .getElementById(`div_${param}`)
              .classList.add('invalid-box');
          } else {
            document
              .getElementById(`div_${param}`)
              .classList.remove('invalid-box');
          }

          setSimulacao({
            ...simulacao,
            [param]: comprovar,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  const imovelDescricaoButtons = () => {
    return operations.map((operation) => (
      <Button
        key={`${operation.C}_${operation.OPERACAO}`}
        variant={
          simulacao.imovel_codigo === operation.C
            ? buttonColorActive
            : buttonColor
        }
        size='sm'
        className={`m-1 ${
          simulacao.imovel_codigo === operation.C ? 'active' : ''
        }`}
        onClick={() => {
          setSimulacao({
            ...simulacao,
            imovel_codigo: operation.C,
            imovel_descricao: operation.OPERACAO,
          });
          document
            .getElementById('div_imovel_descricao')
            .classList.remove('invalid-box');
        }}
      >
        {operation.OPERACAO}
      </Button>
    ));
  };

  const relacionamentoButtons = () => {
    const relacionamento = [
      'Cônjuge',
      'Companheiro(a)',
      'Pai/Mãe',
      'Filho(a)',
      'Irmão/Irmã',
      'Outro',
    ];

    return relacionamento.map((item) => (
      <Button
        key={item}
        variant={
          simulacao.relacionamento === item ? buttonColorActive : buttonColor
        }
        size='sm'
        className={`m-1 ${simulacao.relacionamento === item ? 'active' : ''}`}
        onClick={() => {
          setSimulacao({
            ...simulacao,
            relacionamento: item,
          });
          document
            .getElementById('div_relacionamento')
            .classList.remove('invalid-box');
        }}
      >
        {item}
      </Button>
    ));
  };

  if (loading)
    return (
      <LoadingDots
        class_op='mt-5'
        images={images}
      />
    );

  return (
    <div className='wrapper'>
      <SEO
        title={`HamannCorp | Simulação online de Financiamento Imobiliário`}
        description='Simule agora o melhor Financiamento Imobiliário para você. Preencha o formulário e receba gratuitamente uma avaliação personalizada, com a análise do seu perfil e dos bancos e produtos disponíveis para você.'
        name={'HamannCorp'}
        type='website'
      />
      <NavTheme />
      <div className='simulador'>
        <header className='d-flex flex-column text-center'>
          <a
            href='https://www.hamanncorp.com'
            rel='noreferrer'
            target='_blank'
            aria-label='HamannCorp'
            title='HamannCorp'
          >
            <img
              src={images[theme].logotext}
              className='formLogoHeader wpMax250'
              alt='logo'
              aria-label='HamannCorp'
              title='HamannCorp'
            />
          </a>
          <h1 className={`mt-5 mb-3 fw600 fs26 text-center header-simulador`}>
            Simulador de financiamento imobiliário
          </h1>
          <h2 className='fw600 fs12'>
            Esse é o simulador mais personalizado que você vai encontrar!
          </h2>
          <p className={`fs10 w60 mAuto my-3 disclaimer-simulador`}>
            Preencha os campos e encontraremos o melhor financiamento para você.
            Os campos marcados com {reqField()} são obrigatórios
          </p>
        </header>
        <div className='mw600'>
          <Form
            onSubmit={handleSubmit}
            autoComplete='on'
            data-bs-theme={theme}
          >
            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
              Sobre Você
            </p>

            <FormGroup
              id='nome'
              label='Nome completo'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: true,
                value: simulacao.nome,
                onChange: (e) => {
                  validate(e, is_compound_name(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_compound_name(e.target.value));
                },
              }}
            />

            <FormGroup
              id='email'
              label='E-mail'
              addFormControl={true}
              formControlProps={{
                type: 'email',
                required: true,
                value: simulacao.email,
                onChange: (e) => {
                  validate(e, is_valid_email(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_email(e.target.value));
                },
              }}
            />

            <FormGroup
              id='whatsapp'
              label='WhatsApp'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '(00) 00000-0000',
                required: true,
                value: simulacao.whatsapp,
                onChange: (e) => {
                  validate(e, is_valid_phone(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_phone(e.target.value));
                },
              }}
            />

            <FormGroup
              id='cpf'
              label='CPF'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '000.000.000-00',
                required: true,
                value: simulacao.cpf,
                onChange: (e) => {
                  validate(e, is_valid_cpf(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_cpf(e.target.value));
                },
              }}
            />

            <FormGroup
              id='nascimento'
              label='Data de nascimento'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                as: IMaskInput,
                mask: '00/00/0000',
                required: true,
                value: simulacao.nascimento,
                onChange: (e) => {
                  validate(e, is_valid_nascimento(e.target.value));
                },
                onBlur: (e) => {
                  validate(e, is_valid_nascimento(e.target.value));
                },
              }}
            />

            <div
              className='bgContrastSoftDarker p-3 mt-4'
              id='div_estado_civil'
            >
              <span className='mt-4 textContrast fw500 fs10'>
                Estado Civil {reqField()}
              </span>
              <div className='divButtons'>{estadoCivilButtons()}</div>
            </div>

            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
              Sobre o seu futuro imóvel
            </p>

            <FormGroup
              id='imovel_valor'
              label='Valor do imóvel'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: true,
                value: simulacao.imovel_valor,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            {cities.length > 0 ? (
              <Form.Group
                className='bgContrastSoftDarker p-3 mt-4'
                id='box_imovel_cidade'
              >
                <Form.Label
                  htmlFor='imovel_cidade'
                  className='input-label textContrast fw500 fs10'
                >
                  Cidade do imóvel {reqField()}
                </Form.Label>
                <Form.Select
                  id='imovel_cidade'
                  name='imovel_cidade'
                  required
                  value={simulacao.imovel_cidade}
                  onChange={(e) => {
                    validate(e, not_blank(e.target.value));
                  }}
                >
                  <option value=''>Selecione</option>
                  {cities.map((city) => (
                    <option
                      key={city.codigo_cef}
                      value={city.nome}
                    >
                      {city.nome}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type='invalid'
                  className='mt-0 f08'
                >
                  Informe a cidade do imóvel
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              ''
            )}

            <div
              className='bgContrastSoftDarker p-3 mt-4'
              id='div_imovel_descricao'
            >
              <span className='d-flex my-1 textContrast fw500 fs10'>
                Tipo de Financiamento {reqField()}
              </span>
              <div className='divButtons'>
                {operations.length > 0 && imovelDescricaoButtons()}
              </div>
            </div>

            <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
              <Form.Label
                htmlFor='corretor'
                className='input-label textContrast fw500 fs10'
              >
                Corretor
              </Form.Label>
              <Form.Control
                type='text'
                id='corretor'
                name='corretor'
                value={simulacao.corretor}
                disabled={corretor ? true : false}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>
            <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
              <Form.Label
                htmlFor='imobiliaria'
                className='input-label textContrast fw500 fs10'
              >
                Imobiliária
              </Form.Label>
              <Form.Control
                type='text'
                id='imobiliaria'
                name='imobiliaria'
                value={simulacao.imobiliaria}
                disabled={corretor ? true : false}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>

            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
              Perfil de Financiamento
            </p>

            <FormGroup
              id='renda'
              label='Renda bruta mensal'
              addFormControl={true}
              formControlProps={{
                type: 'text',
                required: true,
                value: simulacao.renda,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <div
              className='bgContrastSoftDarker p-3 mt-4'
              id='div_comprovar'
            >
              <span className='d-flex my-1 textContrast fw500 fs10'>
                Comprovação de renda {reqField()}
              </span>
              <div className='divButtons'>{comprovarRendaButtons()}</div>
            </div>

            <FormGroup
              id='fgts'
              label='Informe o saldo aproximado de FGTS que pretende utilizar'
              addFormControl={true}
              includeDenyField={true}
              denyFieldText='Não utilizarei saldo de FGTS'
              formControlProps={{
                type: 'text',
                required: true,
                value: simulacao.fgts,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <FormGroup
              id='recursos'
              label='Informe o saldo de outros recursos próprios (exceto FGTS)'
              addFormControl={true}
              includeDenyField={true}
              denyFieldText='Não utilizarei outros recursos próprios'
              formControlProps={{
                type: 'text',
                required: true,
                value: simulacao.recursos,
                onChange: (e) => {
                  validate(e, true, true);
                },
                onKeyUp: (e) => {
                  validate(e, true, true);
                },
              }}
            />

            <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
              <Form.Check
                type='checkbox'
                id='compor'
                required={simulacao.estado_civil.includes('Casado') ? 1 : 0}
                name='compor'
                checked={
                  simulacao.estado_civil.includes('Casado') || simulacao.compor
                }
                className='mb-0 textContrast fw500'
                label={
                  simulacao.estado_civil.includes('Casado') ? (
                    <>
                      Incluir dados do cônjuge para compor renda
                      <span
                        className={`fs10 ${
                          theme === 'light' ? 'cRed' : 'cDarkRed'
                        } `}
                      >
                        {' '}
                        * (obrigatório para casados)
                      </span>
                    </>
                  ) : (
                    'Deseja compor renda com mais uma pessoa?'
                  )
                }
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>

            {full &&
            (simulacao.compor || simulacao.estado_civil.includes('Casado')) ? (
              <div>
                <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
                  Sobre{' '}
                  {simulacao.estado_civil.includes('Casado')
                    ? 'seu cônjuge'
                    : 'a pessoa que irá compor renda com você'}
                </p>

                {full ? (
                  <>
                    {simulacao.estado_civil.includes('Casado') ? (
                      <p className='mt-3 mb-2 f08'>
                        Como você informou ser casado(a), é necessário informar
                        os dados abaixo, mesmo que{' '}
                        {simulacao.estado_civil.includes('Casado')
                          ? 'seu cônjuge'
                          : 'a pessoa que irá compor renda com você'}{' '}
                        não tenha renda.
                      </p>
                    ) : (
                      ''
                    )}

                    <div id='div_relacionamento'>
                      {simulacao.compor ||
                      simulacao.estado_civil.includes('Casado') ? (
                        <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
                          <Form.Label className='input-label textContrast fw500 fs10 w100'>
                            Relacionamento com a pessoa que irá compor renda{' '}
                            {reqField()}
                          </Form.Label>

                          <div className='divButtons'>
                            {relacionamentoButtons()}
                          </div>
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}

                <FormGroup
                  id='outro_nome'
                  label='Nome completo'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    required: true,
                    value: simulacao.outro_nome,
                    onChange: (e) => {
                      validate(e, is_compound_name(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_compound_name(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  id='outro_cpf'
                  label='CPF'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    as: IMaskInput,
                    mask: '000.000.000-00',
                    required: true,
                    value: simulacao.outro_cpf,
                    onChange: (e) => {
                      validate(e, is_valid_cpf(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_valid_cpf(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  id='outro_nascimento'
                  label='Data de nascimento'
                  addFormControl={true}
                  formControlProps={{
                    type: 'text',
                    as: IMaskInput,
                    mask: '00/00/0000',
                    required: true,
                    value: simulacao.outro_nascimento,
                    onChange: (e) => {
                      validate(e, is_valid_nascimento(e.target.value));
                    },
                    onBlur: (e) => {
                      validate(e, is_valid_nascimento(e.target.value));
                    },
                  }}
                />

                <FormGroup
                  id='outro_renda'
                  label='Renda bruta mensal'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui renda'
                  formControlProps={{
                    type: 'text',
                    required: true,
                    value: simulacao.outro_renda,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <FormGroup
                  id='outro_fgts'
                  label='Informe o saldo aproximado de FGTS que pretende utilizar'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui saldo de FGTS'
                  formControlProps={{
                    type: 'text',
                    required: true,
                    value: simulacao.outro_fgts,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <FormGroup
                  id='outro_recursos'
                  label='Informe o saldo de outros recursos próprios (exceto FGTS)'
                  addFormControl={true}
                  includeDenyField={true}
                  denyFieldText='Não possui outros recursos próprios'
                  formControlProps={{
                    type: 'text',
                    required: true,
                    value: simulacao.outro_recursos,
                    onChange: (e) => {
                      validate(e, true, true);
                    },
                    onKeyUp: (e) => {
                      validate(e, true, true);
                    },
                  }}
                />

                <div
                  className='bgContrastSoftDarker p-3 mt-4'
                  id='div_outro_comprovar'
                >
                  <span className='d-flex my-1 textContrast fw500 fs10'>
                    Comprovação de renda dessa pessoa {reqField()}
                  </span>
                  <div className='divButtons'>
                    {comprovarRendaButtons(true)}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
              {simulacao.compor || simulacao.estado_civil.includes('Casado')
                ? 'Marque abaixo se as opções se aplicam a você ou a quem irá compor renda com você'
                : 'Marque abaixo se as opções se aplicam a você'}
            </p>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                Possui Imóvel Registrado {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='possui_imovel_sim'
                  className={`radioYesNo  ${
                    simulacao.possui_imovel === '1' ? colorChecked : ''
                  } `}
                  name='possui_imovel'
                  checked={simulacao.possui_imovel === '1'}
                  label='Sim'
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='possui_imovel_nao'
                  checked={simulacao.possui_imovel === '0'}
                  className={`radioYesNo ${
                    simulacao.possui_imovel === '0' ? colorChecked : ''
                  } `}
                  name='possui_imovel'
                  label='Não'
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
              {simulacao.possui_imovel === '1' ? (
                <Form.Group className='my-2'>
                  <Form.Label
                    htmlFor='possui_imovel_cidade'
                    className='input-label textContrast fw500 fs10'
                  >
                    Cidade do imóvel {reqField()}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='possui_imovel_cidade'
                    name='possui_imovel_cidade'
                    required
                    value={simulacao.possui_imovel_cidade}
                    onChange={(e) =>
                      updateSimulacaoState(e.target.id, e.target.value)
                    }
                  />
                </Form.Group>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                É servidor público? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='servidor_publico_sim'
                  name='servidor_publico'
                  className={`radioYesNo ${
                    simulacao.servidor_publico === '1' ? colorChecked : ''
                  } `}
                  label='Sim'
                  checked={simulacao.servidor_publico === '1'}
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />{' '}
                <Form.Check
                  type='radio'
                  id='servidor_publico_nao'
                  name='servidor_publico'
                  className={`radioYesNo ${
                    simulacao.servidor_publico === '0' ? colorChecked : ''
                  } `}
                  label='Não'
                  checked={simulacao.servidor_publico === '0'}
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                Possui 3 anos de trabalho registrado, considerando todos os
                empregos que já teve? (mesmo se atualmente não estiver
                registrado) {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='anos_fgts_sim'
                  className={`radioYesNo ${
                    simulacao.anos_fgts === '1' ? colorChecked : ''
                  } `}
                  name='anos_fgts'
                  checked={simulacao.anos_fgts === '1'}
                  label='Sim'
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='anos_fgts_nao'
                  checked={simulacao.anos_fgts === '0'}
                  className={`radioYesNo ${
                    simulacao.anos_fgts === '0' ? colorChecked : ''
                  } `}
                  name='anos_fgts'
                  label='Não'
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                Possui dependentes (filhos ou cônjuge)? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='dependentes_sim'
                  className={`radioYesNo ${
                    simulacao.dependentes === '1' ? colorChecked : ''
                  } `}
                  name='dependentes'
                  checked={simulacao.dependentes === '1'}
                  label='Sim'
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='dependentes_nao'
                  checked={simulacao.dependentes === '0'}
                  className={`radioYesNo ${
                    simulacao.dependentes === '0' ? colorChecked : ''
                  } `}
                  name='dependentes'
                  label='Não'
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                Atualmente registrado CLT? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='clt_sim'
                  className={`radioYesNo ${
                    simulacao.clt === '1' ? colorChecked : ''
                  } `}
                  name='clt'
                  checked={simulacao.clt === '1'}
                  label='Sim'
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='clt_nao'
                  checked={simulacao.clt === '0'}
                  className={`radioYesNo ${
                    simulacao.clt === '0' ? colorChecked : ''
                  } `}
                  name='clt'
                  label='Não'
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
              <p className='mb-1 textContrast fw500 fs10'>
                Foi beneficiado por algum programa habitacional? {reqField()}
              </p>
              <div className='mt-0 divYesNo'>
                <Form.Check
                  required
                  type='radio'
                  id='beneficiado_sim'
                  className={`radioYesNo ${
                    simulacao.beneficiado === '1' ? colorChecked : ''
                  } `}
                  name='beneficiado'
                  checked={simulacao.beneficiado === '1'}
                  label='Sim'
                  value={'1'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
                <Form.Check
                  type='radio'
                  id='beneficiado_nao'
                  checked={simulacao.beneficiado === '0'}
                  className={`radioYesNo ${
                    simulacao.beneficiado === '0' ? colorChecked : ''
                  } `}
                  name='beneficiado'
                  label='Não'
                  value={'0'}
                  onChange={(e) =>
                    updateSimulacaoState(e.target.name, e.target.value)
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
              <Form.Label
                htmlFor='informacoes'
                className='input-label textContrast fw500 fs10'
              >
                Informações adicionais
              </Form.Label>
              <Form.Control
                as='textarea'
                id='informacoes'
                name='informacoes'
                rows={3}
                value={simulacao.informacoes}
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.value)
                }
              />
            </Form.Group>

            <Form.Group className='bgContrastSoftDarker p-3 my-4 textContrast fw500 fs10'>
              <Form.Check
                required
                id='compartilhar_corretor'
                name='compartilhar_corretor'
                checked={simulacao.compartilhar_corretor}
                className='m-0'
                label={
                  <span>
                    Concordo em compartilhar as informações necessárias com o
                    meu corretor
                    <br />
                    <span className='d-flex flex-column mt-1 fs10'>
                      <span className='fw600 ffSoft textContrastLow'>
                        Por que isso é essencial?
                      </span>
                      <span className='mt-1 faJustify'>
                        O seu corretor de imóveis é o profissional que apoiará
                        no envio de documentos (inclusive a documentação do
                        vendedor), acompanhando vistoria de engenharia e outros
                        procedimentos. Se você não tem um corretor, desmarque
                        essa opção.
                      </span>
                      <span className='mt-1 textContrastLow'>
                        Se você mudar de ideia, basta nos informar.
                      </span>
                    </span>
                  </span>
                }
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>
            <Form.Group className='bgContrastSoftDarker p-3 my-4 textContrast fw500 fs10'>
              <Form.Check
                required
                id='termos'
                name='termos'
                checked={simulacao.termos}
                className='m-0'
                label={
                  <span>
                    Concordo com os{' '}
                    <a
                      href='https://www.hamanncorp.com/privacidade'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Termos e Condições'
                      title='Termos e Condições'
                    >
                      termos e condições
                    </a>{' '}
                    {reqField()}
                  </span>
                }
                feedback='É necessário concordar com os termos antes de prosseguir.'
                feedbackType='invalid'
                onChange={(e) =>
                  updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
                }
              />
            </Form.Group>
            {loading ? (
              <LoadingDots images={images} />
            ) : (
              <div className='text-center mb-3 mt-5'>
                <Button
                  type='submit'
                  variant='primary'
                >
                  Enviar simulação agora
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
